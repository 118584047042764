import React, { Fragment } from "react"
import VideoBooking from "../components/VideoBooking"
import { Router } from "@reach/router"
import LoadingBackdrop from "../components/LoadingBackDrop"

const browser = typeof window !== "undefined" && window

function videoBooking({ location }) {
  return browser ? (
    <Router primary={false} component={Fragment}>
      <VideoBooking path={"/videobooking/:id"} location={location} />
    </Router>
  ) : (
    <LoadingBackdrop open />
  )
}

export default videoBooking
