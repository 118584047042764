import { Box, makeStyles } from "@material-ui/core"
import React from "react"
import queryString from "query-string"
import { IconButton } from "gatsby-theme-material-ui"
import CancelIcon from "@material-ui/icons/Cancel"

const useStyles = makeStyles(() => ({
  main: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    background: "white",
  },
}))

export default function VideoBooking({ id, location }) {
  const classes = useStyles()
  const { pro } = queryString.parse(location.search)
  return (
    <Box className={classes.main}>
      <iframe
        allow="camera;microphone;speaker;display-capture"
        src={`https://main.dsc3qyvy1mibr.amplifyapp.com/ng/${id}`}
        allowFullScreen
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: 0,
        }}
      />
      <IconButton to={`${pro ? "/dashboard" : ""}/booking/${id}`}>
        <div
          style={{
            position: "absolute",
            left: 20,
            top: 20,
            width: 60,
            height: 60,
            background: "white",
            borderRadius: 30,
          }}
        >
          <CancelIcon style={{ width: 60, height: 60 }} />
        </div>
      </IconButton>
    </Box>
  )
}
